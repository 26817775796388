body, html {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.ds--app {
  // flex: 1; display: flex; flex-direction: column;
  z-index: 1;
  display: flex;
  position: relative;
  flex-grow: 1;
  min-height: 100%;
}

.ds--navigation {
  top: 0;
  left: 0;
  width: 240px;
  bottom: 0;
  position: fixed;
  min-height: 64px;
  border-right: 1px solid #eeeeee;
}

.ds--sidebar {
  width: 240px;
  background: #f3f4f7;
}

.ds--content {
  margin-left: 240px;
  width: 100%;
  display: flex;
  min-width: 0;
  flex-direction: column;
}

.ds--main {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 64px;
}

.ds--page {
  display: flex;
  flex: 1;
}

.ds--text-editor__composer {
  font-size: 1rem;
}

.ds--text-editor__toolbar {
  border-bottom: 1px solid #cbcbcb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fbfbfb inset !important;
}
